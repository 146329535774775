import React from "react"
import { Router } from "@reach/router"
import ArticleTemplate from "../templates/ArticleTemplate"
import "../styles/index.scss"

const Article = () => {
  return (
    <Router>
      <ArticleTemplate path="/article/:title" />
    </Router>
  )
}
export default Article
