import React, {useEffect, useState} from 'react';
import { Link } from "gatsby";
import ReactMarkdown from 'react-markdown';

import Header from 'src/components/articles-page/Header';
import Footer from "src/components/Footer";
import Carosel from 'src/components/articles-page/Carosel';
import useDataPosts from 'src/hooks/useDataPosts';
import { TagItem, CategoryItem, ThumbnailPost } from 'src/components/articles-page/Posts';
import { getFilteredCategories, sortByTags, getTopArticles } from "src/helpers";

const Content = (props) => {
  const {post, postLink} = props;
  const {
    body,
    media: {data: mediaData},
    tags: {data: tagsList}
  } = post;

  // const copyText = () => {
  //   navigator.clipboard.writeText(postLink);
  // }

  return (
    <div className="container template-main">      
      <img src={mediaData[0]?.attributes?.url} alt="article-image" />

      <div className="post__tags-wrapper">
        {tagsList?.map((tag) => (
          <TagItem key={tag?.id} name={tag?.attributes?.name} />
        ))}
      </div>

      <div className='content'>
        <ReactMarkdown children={body} />
      </div>

      {/* <div className="template__social">
        <a href="#" className="template__social-icon icon-article-facebook" target="_blank" rel="noopener noreferrer" />
        <a href="#" className="template__social-icon icon-article-twitter" target="_blank" rel="noopener noreferrer" />
        <span className="template__social-icon icon-article-link" onClick={copyText} />
      </div> */}
    </div>
  );
};

const ArticleTemplate = (props) => {
  const {location: { href }, title} = props;
  const [post, setPost] = useState(null);  
  const { categories, posts: {data: posts} } = useDataPosts();
  const topArticles = getTopArticles(posts);
 
  useEffect(() => {
    const id = title.split('-').pop();
    const currentPost = posts?.filter((item) => item.id === +id);
    setPost(currentPost);
  }, [title, posts]);

  return (
    <div className="layout-container posts-wrapper template-wrapper">
      <Header />
      <main>
        {
          post?.map(post => (
            <Content key={post?.id} post={post?.attributes} postLink={href} />
          ))
        }

        <div className="gap" />

        <div className="container posts-line-wrapper">
          <h2>Read next</h2>
          <Carosel>
            <div className="posts-line">
              {sortByTags(posts, post)
                ?.slice(0, 4)
                .map(post => <ThumbnailPost key={post?.id} postId={post?.id} post={post?.attributes} />)}
            </div>
          </Carosel>
        </div>

        <div className="gap" />

        <div className="template-wrapper__categories">
          <div className="container">
            <h4>A lot about:</h4>
            <div className="template__categories">
              { getFilteredCategories(categories)
                .map((categorie, index) => (
                  <Link to={`/category/${categorie?.Name}`} key={index} className="link-route" >
                    <CategoryItem key={index} color={categorie.Color} name={categorie.Name} />
                  </Link>
                )
              )}
            </div>
          </div>
        </div>
          

        <div className="gap" />

        <div className="container posts-line-wrapper">
          <h2>Top articles</h2>
          <Carosel>
            <div className="posts-line">
              { topArticles?.map(post => <ThumbnailPost key={post?.id} postId={post?.id} post={post?.attributes} />) }
            </div>
          </Carosel>
        </div>

        <div className="gap" />
        <div className="gap" />
      </main>
      <Footer />
    </div>
  );
};

export default ArticleTemplate;
